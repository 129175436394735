import React from "react";
import NodeNewsTeaser from "components/News/NodeNewsTeaser";

export function NodeNewsList({ nodes }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-24 md:gap-12">
      {nodes.map((node) => (
        <div key={node.id}>
          <NodeNewsTeaser node={node} fill />
        </div>
      ))}
    </div>
  );
}

export function NodeNewsListPreview({ nodes }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-24">
      {nodes.map((node) => (
        <div key={node.id}>
          <NodeNewsTeaser node={node} wide />
        </div>
      ))}
    </div>
  );
}
