export function formatDate(input = null) {
  const date = new Date(input);
  return date.toLocaleDateString("fr-FR", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function splitDateDayMonth(input = null) {
  const date = new Date(input);
  return {
    month: date.toLocaleDateString("fr-FR", {
      month: "short",
    }),
    day: date.toLocaleDateString("fr-FR", {
      day: "numeric",
    }),
  };
}

export function formatDateYMD(input = null) {
  const date = new Date(input);
  let month = `${date.getMonth() + 1}`;
  let day = `${date.getDate()}`;
  const year = date.getFullYear();

  if (month.length < 2) month = `0 ${month}`;
  if (day.length < 2) day = `0 ${day}`;

  return [year, month, day].join("-");
}

export function splitDate(input = null) {
  const date = new Date(input);
  return {
    month: date.toLocaleDateString("fr-FR", {
      month: "long",
    }),
    day: date.toLocaleDateString("fr-FR", {
      day: "numeric",
    }),
    year: date.toLocaleDateString("fr-FR", {
      year: "numeric",
    }),
  };
}
