import React, { createContext, useEffect, useState } from "react";

export const ScrollContext = createContext({});

const ScrollProvider = ({ children }) => {
  const [scrollDir, setScrollDir] = useState("none");
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const threshold = 110;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;
      setScrollY(window.scrollY);

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  return (
    <ScrollContext.Provider value={{ scrollDir, scrollY }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollProvider;
