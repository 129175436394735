import React from "react";
import {
  ExternalLinkIcon,
  LinkIcon,
  MailIcon,
  DeviceMobileIcon,
} from "@heroicons/react/outline";
import isRelative from "lib/isRelative";
import Link from "next/link";
import Button from "components/Button";

export default function LinksBlock({ data, ...props }) {
  return (
    <div {...props}>
      {data.field_links.length > 0 && (
        <ul>
          {data.field_links.map((link, index) => {
            const type = link.url.split(":")[0];
            const isExternal = !isRelative(link.url);
            let IconComponent = LinkIcon;
            if (type === "tel") {
              IconComponent = DeviceMobileIcon;
            } else if (type === "mailto") {
              IconComponent = MailIcon;
            } else if (isExternal) {
              IconComponent = ExternalLinkIcon;
            }
            return type !== "tel" && type !== "mailto" ? (
              <li className="mb-2" key={index}>
                <Link
                  href={link.url}
                  target={isExternal ? "blank" : "self"}
                  passHref
                >
                  <a className="flex items-center">
                    <Button theme="white" size="default" inverse>
                      {link.title}
                    </Button>
                  </a>
                </Link>
              </li>
            ) : (
              <li className="mb-2 text-xl" key={index}>
                <Link
                  href={link.url}
                  target={isExternal ? "blank" : "self"}
                  passHref
                >
                  <a className="hover:text-primary-light flex items-center justify-center text-2xl ">
                    <IconComponent className="inline-block h-8 w-8 mr-2 " />
                    <span>{link.title}</span>
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
