import React, { forwardRef } from "react";
import classNames from "lib/classNames";

const Button = forwardRef(
  (
    {
      children,
      theme = "primary",
      as = "button",
      size = "default",
      inverse = false,
      ...props
    },
    ref
  ) => {
    const Tag = as;

    const themes = {
      primary: {
        default:
          "border-white border-2 bg-primary-dark/20 text-white inline-block text-sm uppercase font-medium tracking-wider transition w-32",
      },
      secondary: {
        default:
          "border-white border-2 bg-secondary inline-block text-sm uppercase font-medium tracking-wider transition w-32",
      },
      tertiary: {
        default:
          "border-tertiary-dark bg-tertiary-dark text-white hover:bg-transparent hover:text-tertiary-dark",
        inverse:
          "border-tertiary-dark bg-transparent text-tertiary-dark hover:bg-tertiary-dark hover:text-white",
      },
      white: {
        default:
          "border-white border-2 bg-transparent text-white inline-block uppercase font-medium tracking-wider transition hover:bg-white hover:text-black duration-300",
        inverse:
          "border-2 border-black text-black bg-transparent inline-block uppercase font-medium tracking-wider transition hover:bg-black hover:text-white duration-300",
      },
      transparent: {
        default:
          "uppercase text-white after:content-[''] after:block after:absolute after:left-0 after:h-1 after:w-16 after:bg-secondary after:ml-2",
      },
      pager: {
        default:
          "uppercase border-2 text-black w-10 h-10 text-center block border-black leading-none",
        inverse:
          "uppercase text-white bg-secondary w-9 h-9 text-center block leading-none",
      },
    };

    const sizes = {
      small: "p-2 text-lg",
      default: "p-2 text-2xl",
      large: "px-3 py-1.5 text-3xl",
    };

    return (
      <Tag
        ref={ref}
        className={classNames(
          inverse ? themes[theme].inverse : themes[theme].default,
          sizes[size]
        )}
        {...props}
      >
        {children}
      </Tag>
    );
  }
);

Button.displayName = "Button";
export default Button;
