import Body from "components/Body";
import React from "react";

export default function BasicBlock({ data, ...props }) {
  return (
    <div {...props}>
      <div className="text-2xl uppercase">
        <Body value={data.body.value} />
      </div>
    </div>
  );
}
