import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";

export default function PrevArrow({ className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <ChevronLeftIcon className="h-full w-full text-black" />
    </div>
  );
}
