import React from "react";
import Image from "next/image";
import Link from "next/link";
import classNames from "lib/classNames";

export default function Branding({
  home,
  theme,
  size = "large",
  alt = "Logo “Interco’Outremer“",
}) {
  return home ? (
    <BrandingContent theme={theme} size={size} />
  ) : (
    <Link href="/" passHref>
      <a className="no-underline">
        <BrandingContent alt={alt} theme={theme} size={size} />
      </a>
    </Link>
  );
}

function BrandingContent({ alt, theme, size }) {
  return (
    <>
      <h1 className="sr-only">Interco Outremer</h1>
      <div className="flex items-center justify-center sm:space-x-4">
        <div
          className={classNames(
            size === "small" && "w-20 h-20",
            size === "medium" && "w-24 h-24",
            size === "large" && "w-28 h-28",
            "flex-initial"
          )}
          style={{ letterSpacing: 0, wordSpacing: 0, fontSize: 0 }}
        >
          {theme === "white" ? (
            <Image
              src="/images/Logo_Interco_TexteBlanc.png"
              height={112}
              width={112}
              layout="responsive"
              alt={alt}
            />
          ) : (
            <Image
              src="/images/Logo_Interco_TexteNoir.png"
              height={size === "small" ? 80 : 100}
              width={size === "small" ? 80 : 100}
              layout="responsive"
              alt={alt}
            />
          )}
        </div>
      </div>
    </>
  );
}
