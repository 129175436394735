import React from "react";
import PreviewAlert from "components/PreviewAlert";
import FooterMenu from "components/Menu/FooterMenu";
import Branding from "components/Branding";
import { ResizeProvider } from "contexts/ResizeContext";
import BasicBlock from "components/Block/BasicBlock";
import LinksBlock from "components/Block/LinksBlock";
import Link from "next/link";
import { NodeNewsListPreview } from "components/News/NodeNewsList";
import Button from "components/Button";
import NodePartnersList from "components/Partners/NodePartnersList";
import Image from "next/image";
import ScrollProvider from "contexts/ScrollContext";
import Navbar from "components/Navbar";

export default function Layout({
  children,
  globals,
  newses,
  partners,
  home = false,
}) {
  return (
    <ResizeProvider>
      <PreviewAlert />
      <ScrollProvider>
        <Navbar home={home} globals={globals} />
        <main>{children}</main>
        {newses && (
          <div className="relative bg-primary overflow-hidden">
            <div
              className="absolute -top-20 -left-20 opacity-20 hidden lg:block"
              style={{ width: "69vw" }}
            >
              <Image
                src="/images/decorations/guadeloupe.svg"
                height={1512}
                width={1319}
                alt="Réprésentation de la Guadeloupe"
                layout="responsive"
              />
            </div>
            <div
              className="absolute -right-60 top-1/4 opacity-20 hidden lg:block"
              style={{ width: "24vw" }}
            >
              <Image
                src="/images/decorations/guyane.svg"
                height={910}
                width={724}
                alt="Réprésentation de la Guadeloupe"
                layout="responsive"
              />
            </div>
            <section className="relative pt-14 lg:pb-60 pb-28">
              <div className="relative container mx-auto px-4">
                <div>
                  <h2 className="mb-8 text-5xl uppercase text-white font-important after:h-1 after:w-16 after:bg-secondary after:sm:ml-4 ml-0 after:block after:mt-3 after:sm:mt-0s after:sm:inline-block">
                    Nos actualit&eacute;s
                  </h2>
                </div>

                {home && (
                  <p className="w-full lg:w-1/2 text-white mb-20 text-lg">
                    Interco&rsquo;Outre-mer rassemble les institutions &agrave;
                    vocation intercommunale, anime l&rsquo;intercommunalit&eacute;
                    en Outre-mer depuis 20 ans et initie des &eacute;changes
                    permanents avec ses adh&eacute;rents dans un esprit de partage
                    d&rsquo;exp&eacute;riences.
                  </p>
                )}                

                {newses?.length ? (
                  <NodeNewsListPreview nodes={newses} />
                ) : (
                  <p className="py-4">Aucun contenu trouvé</p>
                )}

                <div className="text-center mt-12">
                  <Link href="/actualites" passHref>
                    <Button as="a" theme="white">
                      Voir toute l&apos;actualit&eacute;
                    </Button>
                  </Link>
                </div>
              </div>
              {partners && partners.length > 0 && (
                <div className="relative py-10 bg-white/50 mt-14">
                  <div className="relative container mx-auto px-4">
                    <div>
                      <NodePartnersList nodes={partners} />
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        )}
        <footer className="bg-primary-lighter relative">
          <div
            className="absolute top-0.5 inset-x-0 -translate-y-full z-10 bg-ellipse-footer bg-cover bg-top"
            style={{ paddingBottom: "8%" }}
          />
          <div className="lg:absolute xl:-top-1/3 lg:left-1/2 lg:-top-1/4 lg:-translate-x-1/2 relative z-20">
            <Branding home={home} theme="white" size="large" />
          </div>
          <div>
            <div className="container mx-auto px-4">
              <div className="py-20 grid grid-cols-1 md:grid-cols-3 gap-4 justify-items-center gap-y-10 md:gap-y-4">
                {globals?.blocks?.address && (
                  <BasicBlock
                    data={globals.blocks.address}
                    className="footer-text text-center md:text-left"
                  />
                )}
                {globals?.blocks?.contact && (
                  <LinksBlock
                    data={globals.blocks.contact}
                    className="footer-text  text-center md:text-left"
                  />
                )}
                {globals?.menus?.footer && (
                  <FooterMenu menu={globals.menus.footer} />
                )}
              </div>
            </div>
          </div>
        </footer>
      </ScrollProvider>
    </ResizeProvider>
  );
}
