import React, { createRef, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import HeroIcon from "components/HeroIcon";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import { ResizeContext } from "contexts/ResizeContext";
import Branding from "components/Branding";
import Link from "next/link";
import { Transition } from "@headlessui/react";

export default function MainMenu({ menu, isAffix, depth = null }) {
  const mobileBreakpoints = ["sm", "md", "lg", "xl"];
  const { breakpoint } = useContext(ResizeContext);
  const [open, setOpen] = useState(false);
  const [forceOpen, setForceOpen] = useState(false);

  const closeByDisplay = () => {
    if (mobileBreakpoints.includes(breakpoint)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (typeof breakpoint !== "undefined") {
      setOpen(!mobileBreakpoints.includes(breakpoint));
      setForceOpen(mobileBreakpoints.includes(breakpoint));
    }
  }, [breakpoint]);

  useEffect(() => {
    if (mobileBreakpoints.includes(breakpoint)) {
      document.body.style.overflow = open ? "hidden" : "visible";
    }
  }, [open]);

  return (
    menu && (
      <div
        id="mainMenu"
        className="absolute right-4 xl:relative xl:z-10"
        aria-labelledby="mainMenuToggle"
      >
        <button
          id="mainMenuToggle"
          className="xl:sr-only text-center"
          onClick={() => setOpen(!open)}
          aria-expanded={open}
          type="button"
        >
          <span
            className={classNames(
              "inline-block p-3 transition",
              !isAffix && "text-white"
            )}
          >
            <MenuIcon className="h-16 w-16" />
          </span>
          <span className="block sr-only font-semibold">Menu</span>
        </button>
        <Transition
          as="div"
          id="mainMenuWrapper"
          show={open}
          enter="transition-transform ease-in-out duration-300 xl:duration-0"
          enterFrom="translate-x-full xl:translate-x-0"
          enterTo="translate-x-0"
          leave="transition-transform ease-in-out duration-300 xl:duration-0"
          leaveFrom="translate-x-0 xl:translate-x-full"
          leaveTo="translate-x-full"
        >
          <div className="absolute top-8 right-4 md:right-16 xl:hidden">
            <button
              className="inline-block p-4 text-white"
              onClick={() => setOpen(false)}
              type="button"
            >
              <XIcon className="w-16 h-16 text-primary" />
              <span className="sr-only">Fermer</span>
            </button>
          </div>

          <div className="absolute top-8 left-4 md:left-16 xl:hidden">
            <Branding />
          </div>

          <nav className="container xl:max-w-none mx-auto xl:mx-0 px-4 py-28 xl:p-0 xl:flex justify-between">
            <MainMenuItems
              menu={menu}
              forceOpen={forceOpen}
              closeParent={closeByDisplay}
              depth={depth}
              isAffix={isAffix}
            />
          </nav>
        </Transition>
      </div>
    )
  );
}

function MainMenuItems({
  menu,
  depth,
  forceOpen,
  closeParent,
  isAffix,
  level = 0,
}) {
  const [open, setOpen] = useState();
  const menuItemRef = createRef();
  const router = useRouter();

  const closeWithParent = () => {
    setOpen(false);
    closeParent();
  };

  // Mount == open
  useEffect(() => {
    const keyDownEventHandler = (event) => {
      switch (event.key) {
        case "Escape":
          closeWithParent();
          break;
        default:
          break;
      }
    };

    const mouseDownEventHandler = (event) => {
      if (
        !forceOpen &&
        menuItemRef.current &&
        !menuItemRef.current.contains(event.target)
      ) {
        closeWithParent();
      }
    };

    if (typeof document !== "undefined") {
      document.addEventListener("keydown", keyDownEventHandler);
      document.addEventListener("mousedown", mouseDownEventHandler);

      return () => {
        document.removeEventListener("keydown", keyDownEventHandler);
        document.removeEventListener("mousedown", mouseDownEventHandler);
      };
    }
    return undefined;
  }, []);

  const linkEventHandler = (event) => {
    event.preventDefault();
    router.push(event.currentTarget.href);
    closeParent();
  };

  return (
    <ul ref={menuItemRef} className={`menu-ul-${level}`}>
      {menu.map((item, id) => {
        const hasDropdown = item.items && item.items.length > 0;
        const hasIcon =
          item.options &&
          item.options.attributes &&
          item.options.attributes["data-icon-name"] !== undefined;

        return (
          <li key={item.id} className={`menu-li-${level}`}>
            {depth && depth > level && hasDropdown ? (
              <>
                <button
                  className={classNames(
                    "group",
                    `menu-button-${level}`,
                    isAffix && "text-xl"
                  )}
                  onClick={() => setOpen(id === open ? false : id)}
                  aria-expanded={open === id}
                  type="button"
                >
                  {hasIcon && (
                    <span className="relative hidden xl:inline-block bg-white group-hover:bg-primary transition-colors rounded-full h-11 w-11 mb-.5">
                      <HeroIcon
                        name={item.options.attributes["data-icon-name"]}
                        type={item.options.attributes["data-icon-type"]}
                        className="h-5 w-5 absolute top-1/2 left-1/2 text-white transition-transform transform group-hover:scale-110 -translate-x-1/2 -translate-y-1/2"
                      />
                    </span>
                  )}
                  <span
                    className={classNames(
                      level === 0 ? "block" : "inline-block",
                      "uppercase"
                    )}
                  >
                    {item.title}
                  </span>
                </button>
                {(open === id || forceOpen) && (
                  <MainMenuItems
                    menu={item.items}
                    depth={depth}
                    forceOpen={forceOpen}
                    closeParent={closeWithParent}
                    level={level + 1}
                  />
                )}
              </>
            ) : (
              <a
                href={item.url}
                className={classNames(
                  "group",
                  isAffix
                    ? `xl:menu-fixed-a-${level} menu-a-${level}`
                    : `menu-a-${level}`,
                  router.asPath === item.url &&
                    "underline xl:no-underline xl:text-gray-500"
                )}
                onClick={linkEventHandler}
                aria-current={router.asPath === item.url ? "page" : false}
              >
                {hasIcon && (
                  <span className="relative hidden xl:inline-block bg-white group-hover:bg-primary transition-colors rounded-full h-11 w-11 mb-.5">
                    <HeroIcon
                      name={item.options.attributes["data-icon-name"]}
                      type={item.options.attributes["data-icon-type"]}
                      className="h-5 w-5 absolute top-1/2 left-1/2 text-white transition-transform transform group-hover:scale-110 -translate-x-1/2 -translate-y-1/2"
                    />
                  </span>
                )}
                <span className="uppercase">{item.title}</span>
              </a>
            )}
          </li>
        );
      })}
      <li className="xl:hidden">
        <Link href="/contact" passHref>
          <a className="text-3xl px-3 py-1 bg-primary text-white uppercase font-medium tracking-wider transition leading-none hover:saturate-150 duration-300">
            Nous contacter
          </a>
        </Link>
      </li>
      <li className="xl:hidden">
        <Link href="/adherer" passHref>
          <a className="text-3xl px-3 py-1 bg-secondary uppercase font-medium tracking-wider transition leading-none hover:saturate-150 duration-300">
            Adhérer
          </a>
        </Link>
      </li>
    </ul>
  );
}
