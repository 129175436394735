import React from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";

export default function NextArrow({ className, onClick }) {
  return (
    <div className={className} onClick={onClick}>
      <ChevronRightIcon className="h-full w-full text-black" />
    </div>
  );
}
