import React, { useContext } from "react";
import classNames from "lib/classNames";
import Branding from "components/Branding";
import MainMenu from "components/Menu/MainMenu";
import Link from "next/link";
import { ScrollContext } from "contexts/ScrollContext";

export default function Navbar({ home, globals }) {
  const { scrollDir, scrollY } = useContext(ScrollContext);
  const isAffix = scrollY >= 110;

  return (
    <header
      className={classNames(
        " inset-x-0 z-50 w-full transition-transform",
        scrollDir === "up" && isAffix
          ? "translate-y-0"
          : scrollDir === "down" && isAffix && "transform -translate-y-full",
        isAffix ? "shadow-xl fixed top-0 bg-white" : "absolute top-20"
      )}
    >
      <div
        className={classNames(
          isAffix ? "py-3" : "py-6",
          "container mx-auto px-4 w-full"
        )}
      >
        <div
          className={classNames(
            "relative flex text-center z-30 items-center",
            isAffix && "xl:justify-center"
          )}
        >
          {isAffix ? (
            <Branding home={home} size="small" />
          ) : (
            <Branding home={home} size="medium" />
          )}
          {globals?.menus?.main && (
            <MainMenu menu={globals.menus.main} depth={1} isAffix={isAffix} />
          )}
          <div
            className={classNames(
              "flex gap-8 justify-end items-center",
              isAffix ? "ml-8" : "grow"
            )}
          >
            <Link href="/contact">
              <a
                className={classNames(
                  scrollY >= 110 ? "text-xl p-1.5" : "text-2xl p-2.5",
                  "border-white border-2 bg-primary text-white xl:inline-block hidden uppercase font-medium tracking-wider transition leading-none hover:saturate-150 duration-300"
                )}
              >
                Nous contacter
              </a>
            </Link>
            <Link href="/adherer">
              <a
                className={classNames(
                  scrollY >= 110 ? "text-xl p-1.5" : " text-2xl p-2.5",
                  "border-white border-2 bg-secondary xl:inline-block uppercase font-medium tracking-wider transition hidden leading-none hover:saturate-150 duration-300"
                )}
              >
                Adhérer
              </a>
            </Link>
          </div>
        </div>
      </div>
      <div className="absolute inset-y-0 w-full bg-gradient-to-r from-white via-transparent" />
    </header>
  );
}
