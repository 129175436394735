import React from "react";
import { splitDateDayMonth } from "lib/formatDate";
import Image from "next/image";
import { useRouter } from "next/router";
import classNames from "lib/classNames";
import Body from "components/Body";
import { ChevronRightIcon } from "@heroicons/react/solid";

export default function NodeNewsTeaser({ node, fill }) {
  const router = useRouter();
  const formatedStartDate = splitDateDayMonth(node.field_start_date);
  let formatedEndDate = null;

  if (node.field_end_date !== null) {
    formatedEndDate = splitDateDayMonth(node.field_end_date);
  }

  const clickHandler = () => {
    router.push(node.path.alias);
  };

  return (
    <article
      className="group flex flex-col cursor-pointer relative"
      onClick={() => clickHandler()}
    >
      <div
        className={classNames(
          fill ? "w-full" : "absolute -top-4 -right-4 w-2/3",
          "block p-1 text-center bg-primary-dark text-secondary z-20 text-2xl uppercase"
        )}
      >
        <span className="font-bold">
          {formatedStartDate.day < 10
            ? `0${formatedStartDate.day}`
            : formatedStartDate.day}
        </span>
        &nbsp;
        <span>{formatedStartDate.month}</span>
        {formatedEndDate !== null && (
          <>
            &nbsp;
            <ChevronRightIcon className=" inline-block h-6" />
            &nbsp;
            <span className="font-bold">
              {formatedEndDate.day < 10
                ? ` 0${formatedEndDate.day}`
                : formatedEndDate.day}
            </span>
            &nbsp;
            <span>{formatedEndDate.month}</span>
          </>
        )}
      </div>
      <div className="relative order-2 pt-2">
        <div className="flex flex-col">
          <h2 className="text-2xl font-sans group-hover:decoration-white transition uppercase">
            {node.title}
          </h2>
          <div className="flex flex-row gap-4">
            <span
              className={classNames(
                fill ? "text-secondary" : "text-white",
                "text-lg"
              )}
            >
              #{node.field_type.name}
            </span>
          </div>
          {fill && (
            <div className="line-clamp-4 border-l-4 border-secondary pl-4 text-lg mt-4">
              <Body
                value={
                  node.body.summary
                    ? node.body.summary
                    : node.body.value.split(" ").slice(0, 15).join(" ")
                }
              />
            </div>
          )}
        </div>
      </div>
      {node.field_image?.uri && (
        <div
          className={classNames(
            fill ? "mt-2" : "bg-white overflow-hidden p-4",
            "order-1"
          )}
        >
          <div className="aspect-h-1 aspect-w-1">
            <Image
              src={node.field_image.image_style_uri.large}
              width={node.field_image.resourceIdObjMeta.width}
              height={node.field_image.resourceIdObjMeta.height}
              layout="fill"
              objectFit="cover"
              alt={node.field_image.resourceIdObjMeta.alt}
              className="group-hover:scale-110 transition"
            />
          </div>
        </div>
      )}
    </article>
  );
}
