import React from "react";
import Link from "next/link";
import Image from "next/image";
import Slider from "react-slick";
import NextArrow from "components/Slider/NextArrow";
import PrevArrow from "components/Slider/PrevArrow";

export default function NodePartnersList({ nodes }) {
  const settings = {
    infinite: true,
    rows: 1,
    nextArrow: <NextArrow className="before:content-['']" />,
    prevArrow: <PrevArrow className="before:content-['']" />,
    speed: 500,
    slidesToShow: Math.min(nodes.length, 7),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="slider-partner">
      {nodes.map((node) => (
        <div key={node.id} className="px-4 w-44">
          <div className="aspect-h-1 aspect-w-1">
            <Link href={node.field_link.uri} passHref>
              <a
                title={node.field_link.title}
                className="flex items-center justify-center bg-white"
                target="_blank"
              >
                <Image
                  src={node.field_image.image_style_uri.small}
                  width={node.field_image.resourceIdObjMeta.width}
                  height={node.field_image.resourceIdObjMeta.height}
                  alt={node.field_image.resourceIdObjMeta.alt}
                  layout="intrinsic"
                />
              </a>
            </Link>
          </div>
        </div>
      ))}
    </Slider>
  );
}
