import React from "react";
import Link from "next/link";

export default function FooterMenu({ menu }) {
  return (
    menu && (
      <ul>
        {menu.map((item) => {
          return (
            <li className="text-center md:text-left" key={item.id}>
              <Link href={item.url} passHref>
                <a
                  className="text-2xl relative uppercase after:content-[''] after:absolute after:w-full after:scale-x-0
                 after:h-0.5 after:bottom-0 after:left-0 after:bg-primary-dark after:origin-bottom-right
                 after:transition-transform after:duration-200 hover:after:scale-x-100 hover:after:origin-bottom-left"
                >
                  {item.title}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
}
